import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
const alt = "Atex Park, Stowmarket, South West View";

export default function Banner () {
    return (
        <StaticQuery
            query={graphql`query {
  file(relativePath: {eq: "Atex-Park-Front-View.jpg"}) {
       childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
      }
  }
}`} render={ data => ( 
    <GatsbyImage image={ getImage( data.file ) } alt={alt} />) } />
    );
};
