import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Banner from './banner';

const Header = ({ siteTitle }) => {

const wrapTitle = {
    position:`relative`,
    padding: `1em 0`,
    textAlign:`center`,
    WebkitTransition: `margin-bottom 0.2s`,
    transition: `margin-bottom 0.2s`,
    zIndex: `3`,
}

const titleStyle = {
    clear: `none`,
    color:`var(--dark)`,
    fontSize: `1.3em`,
    fontWeight: `bolder`,
    lineHeight: `1.25`,
    letterSpacing: `0.08em`,
    margin: `0`,
    padding: `0`,
    textTransform: `uppercase`,
};
    return (
  <div style={ wrapTitle }>
       <h1
       style={{
           zIndex: `10`,
    width: `100%`,
    position: `absolute`,
     background: `linear-gradient(to bottom, rgba(255,255,255,1), rgba(255,255,255,0.5))`
       }} 
        ><Link style={ titleStyle } to="http://atextravel.co.uk/" rel="home">ATEX Travel</Link></h1>
        <Banner style={{ width:`100%` }} />
        </div>
);
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
