/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ( { title,  description, date, image, what, audio, tags, schemaMarkup }) => {

const { pathname } = useLocation();

const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            image
            description
            author
          }
        }
      }
    `
)

    const siteUrl = site.siteMetadata.siteUrl;
    const defaultTitle = site.siteMetadata.tile;
    const defaultImage = site.siteMetadata.image;
    const defaultDescription = site.siteMetadata.description;
    const siteAuthor = site.siteMetadata.author;

const seo = {
    title : title || defaultTitle,
    description: description || defaultDescription,
    image : siteUrl + (image || defaultImage),
    url: siteUrl+pathname,
    author: siteAuthor
}


return (
    <Helmet title={seo.title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null} >
    {(schemaMarkup ? true : null) && <script type="application/ld+json" >{`${JSON.stringify(schemaMarkup)}`}</ script>}

    { (what === 'profile' ? true : null) &&
            <head prefix="og: http://ogp.me/ns# profile: http://ogp.me/ns/profile#"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:type" content="profile"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:locale" content="en_GB"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:url" content="https://www.poetry.me.uk/profile"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:image" content="https://www.poetry.me.uk/christian.jpg"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:image:width" content="140"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:image:height" content="200"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="og:image:type" content="image/jpg"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="profile:first_name" content="Christian"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="profile:last_name" content="Lacdael"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="profile:gender" content="male"/>}
    { (what === 'profile' ? true : null) &&
        <meta property="profile:username" content="delete_your_social_media"/>}
    <meta name="description" content={seo.description} />
    <meta name="image" content={seo.image} />

    {seo.url && <meta property="og:url" content={seo.url} />}
    {(what === 'article' || what === 'book' ? true : null) && <meta property="og:type" content={what} />}
    {(what === 'article' || what === 'book' ? true : null) && <meta property={ what +":author"} content={ siteUrl + "/profile" } />}
    {(what === 'article' ? true : null) && <meta property="article:published_time" content={date} /> }
    {(what === 'book' ? true : null) && <meta property="book:release_date" content={date} /> }
    { what && tags && tags.split(" ").map( (t,i) => <meta property={ what +":tag" } key={i}  content={t} /> )}
    {( audio ? true : null) && <meta property="og:audio" content={audio} />}
    {( audio ? true : null) && <meta property="og:audio:type" content="audio/mpeg" />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && <meta property="og:description" content={seo.description} />}
        {seo.image && <meta property="og:image" content={seo.image} />}
           </Helmet>
    );
}
/*
   */
export default SEO
